const apiPath = 'https://alpha.fkprod.ru/actions';
// const apiPath = 'https://fkprod.ru/actions';

const folders = {
  categories: 'categories',
  products: 'products',
  tastes: 'tastes',
  turns: 'turns',
  operations: 'operations',
  nomenclatures: 'nomenclatures',
};

export const getRoutes = {
  getLastCategoryId: () => [apiPath, folders.categories, 'getCategoryId.php'].join('/'),
  getLastProductsId: () => [apiPath, folders.products, 'getProductsId.php'].join('/'),
  getLastTastesId: () => [apiPath, folders.tastes, 'getTastesId.php'].join('/'),
  getLastTurnId: () => [apiPath, folders.turns, 'getTurnsId.php'].join('/'),
  getLastOperationsId: () => [apiPath, folders.operations, 'getOperationsId.php'].join('/'),
  getLastCreatedOperationId: () => [apiPath, folders.operations, 'getCreatedOperationsId.php'].join('/'),
  getLastNomenclaturesId: () => [apiPath, folders.nomenclatures, 'getNomenclaturesId.php'].join('/'),
  getLastNomenclaturesFieldsId: () => [apiPath, folders.nomenclatures, 'getNomenclaturesFieldsId.php'].join('/'),
  getLastNomenclaturesRollsId: () => [apiPath, folders.nomenclatures, 'getNomenclaturesRollsId.php'].join('/'),
};

export default {
  sendEmail: () => [apiPath, 'sendEmail.php'].join('/'),
  setNomenclaturePath: () => [apiPath, folders.nomenclatures, 'setNomenclature.php'].join('/'),
  setOperationPath: () => [apiPath, folders.operations, 'setCreatedOperation.php'].join('/'),
  closeTurnPath: () => [apiPath, folders.turns, 'closeTurn.php'].join('/'),
  setTurnPath: () => [apiPath, folders.turns, 'setTurn.php'].join('/'),
  setCategoryPath: () => [apiPath, folders.categories, 'setCategory.php'].join('/'),
  setProductsPath: () => [apiPath, folders.products, 'setProduct.php'].join('/'),
  setTastesPath: () => [apiPath, folders.tastes, 'setTaste.php'].join('/'),
  loginPath: () => [apiPath, 'getAuth.php'].join('/'),
  dataPath: () => [apiPath, 'data.php'].join('/'),
  loginPage: () => '/login',
  mainPage: () => '/',
  managerPage: () => '/manager',
  notFoundPage: () => '*',
};
